import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { convertThumbnailToModalPictureImage } from '@/modules/Promotions/components/helpers';
import useWindowDimensions from '@/hooks/useWindowDimensions';

type Props = {
  isSettings?: boolean;
  background?: string;
  bottomBlockEnabled?: any;
  pdf:
    | {
        pdfTitle: string;
        pdfDescription: string;
        pdfImageFirst: string;
        pdfImageSecond: string;
        pdfLink: string;
        pdfIsEnabled: boolean;
      }
    | any;
};

function DownloadPDF({
  isSettings,
  background,
  pdf,
  bottomBlockEnabled,
}: Props) {
  const windowSize = useWindowDimensions();
  const enabled = useSettingItem('SUBSCRIPTION_ENABLED');
  return (
    <>
      <Component
        isVisible={!!enabled}
        isSettings={isSettings}
        background={background}
        bottomBlockEnabled={bottomBlockEnabled}
      >
        <Wrapper>
          <Inner>
            <Container>
              <Title>{pdf.pdfTitle.toUpperCase()}</Title>
              <Block>
                <Text>{pdf.pdfDescription}</Text>
              </Block>
              <Button href={pdf.pdfLink} target={'_blank'}>
                Скачать PDF
              </Button>
            </Container>
          </Inner>

          {windowSize.width > 1260 && (
            <>
              <ImageSecond>
                <Picture
                  mobileSmall={convertThumbnailToModalPictureImage(
                    pdf.pdfImageSecond
                  )}
                />
              </ImageSecond>
              <ImageOne>
                <Picture
                  mobileSmall={convertThumbnailToModalPictureImage(
                    pdf.pdfImageFirst
                  )}
                />
              </ImageOne>
            </>
          )}
        </Wrapper>
      </Component>
    </>
  );
}

const Component = styled.div<{
  isVisible?: boolean;
  isSettings?: boolean;
  background?: string;
  bottomBlockEnabled?: any;
}>`
  position: relative;
  width: 100%;
  height: 637.61px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 26;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${(props) =>
      props.background ? `${props.background}` : `${colors.yellow100}`};
    clip-path: polygon(0% 90px, 100% 0, 100% calc(100% - 90px), 0% 100%);
  }

  ${media.laptop(css`
    height: auto;
    &:before {
      clip-path: polygon(0% 60px, 100% 0, 100% calc(100% - 60px), 0% 100%);
    }
  `)}

  ${media.tablet(css`
    margin-bottom: 0;
    &:before {
      clip-path: polygon(0% 36px, 100% 0, 100% calc(100% - 36px), 0% 100%);
    }
  `)}

  ${media.mobile(css`
    &:before {
      clip-path: polygon(0% 30px, 100% 0, 100% calc(100% - 30px), 0% 100%);
    }
  `)}

  ${(props) =>
    props.isSettings
      ? css`
          margin-top: -102px;

          ${media.laptop(css`
            margin-top: -62px;
          `)}
        `
      : ''}

  ${(props) =>
    props.bottomBlockEnabled
      ? css`
          margin-bottom: 32px;
          ${media.laptop(css`
            margin-bottom: 100px;
          `)}
          ${media.tablet(css`
            margin-bottom: 0;
          `)}
        `
      : ''}
`;

const Wrapper = styled.div`
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const ImageOne = styled.div`
  position: absolute;
  right: 40px;
  top: -119px;
  max-width: 460px;
  transform: translateX(-50%);
  box-shadow: 8px 11px 5px 0px rgba(0, 0, 0, 0.25);

  ${media.laptop(css`
    max-width: 306px;
    right: 100px;
    top: -79px;
  `)}
`;

const ImageSecond = styled.div`
  position: absolute;
  right: 40px;
  top: 34px;
  max-width: 460px;
  box-shadow: 8px 11px 5px 0px rgba(0, 0, 0, 0.25);

  ${media.laptop(css`
    top: 24px;
    right: 100px;
    max-width: 306px;
  `)}
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  padding: 130px 0 120px;
  position: relative;
  display: inline-flex;

  ${media.laptop(css`
    padding: 95px 0 66px;
  `)}
`;

const Container = styled.div`
  max-width: 800px;
  margin-right: auto;
  position: relative;

  ${media.laptop(css`
    max-width: 566px;
  `)}
  ${media.tablet(css`
    max-width: 950px;
    margin-left: auto;
    text-align: center;
  `)}
`;

const Title = styled.span`
  display: block;
  padding: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 46px;

  color: ${colors.white};

  ${media.laptop(css`
    font-size: 36px;
    line-height: 1.28;
  `)}

  ${media.tablet(css`
    font-size: 36px;
    line-height: 46px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 46px;
  `)}
`;

const Block = styled.div`
  display: inline-block;
  margin-top: 42px;
  ${media.laptop(css`
    margin-top: 20px;
  `)}
  ${media.tablet(css`
    margin-top: 12px;
  `)}
`;

const Text = styled.span`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;
  display: inline-block;
  position: relative;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0;
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  `)}
`;
const Button = styled.a`
  display: inline-block;
  padding: 31px 26px;
  border-radius: 15px;
  background: #f9683a;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin-top: 42px;

  &:hover {
    transition: 0.3s ease-in;
    opacity: 0.9;
  }
  ${media.laptop(css`
    padding: 18px;
    font-size: 24px;
    margin-top: 25px;
  `)}
  ${media.tablet(css`
    font-size: 18px;
  `)}
  ${media.mobile(css`
    font-size: 16px;
    &:hover {
      transition: none;
      opacity: 1;
    }
  `)}
`;

export default DownloadPDF;
