import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import DownloadPDF from '@/components/DownloadPDF';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import SubscribeFormContainer from './components/SubscribeForm.container';

type Props = {
  isSettings?: boolean;
  background?: string;
  templateFields?: any;
};

function Subscription({ isSettings, background, templateFields }: Props) {
  const enabled = useSettingItem('SUBSCRIPTION_ENABLED');
  return (
    <>
      {templateFields && templateFields.pdfIsEnabled && (
        <DownloadPDF
          pdf={templateFields}
          bottomBlockEnabled={enabled}
          background={'#000000'}
        />
      )}
      <Component
        isVisible={!!enabled}
        isSettings={isSettings}
        background={background}
      >
        <Inner>
          <Container>
            <SubscribeFormContainer />
          </Container>
        </Inner>
      </Component>
    </>
  );
}

const Component = styled.div<{
  isVisible?: boolean;
  isSettings?: boolean;
  background?: string;
}>`
  position: relative;
  margin-top: -122px;
  z-index: 20;
  width: 100%;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.background ? `${props.background}` : `${colors.yellow100}`};
  clip-path: polygon(0% 90px, 100% 0, 100% 100%, 0% 100%);

  ${media.laptop(css`
    margin-top: -160px;
    clip-path: polygon(0% 60px, 100% 0, 100% 100%, 0% 100%);
  `)}

  ${media.tablet(css`
    margin-top: -38px;
    clip-path: polygon(0% 38px, 100% 0, 100% 100%, 0% 100%);
  `)}

  ${media.mobile(css`
    z-index: 2;
    clip-path: polygon(0% 32px, 100% 0, 100% 100%, 0% 100%);
  `)}

  ${(props) =>
    props.isSettings
      ? css`
          margin-top: -102px;

          ${media.laptop(css`
            margin-top: -62px;
          `)}
        `
      : ''}
`;

const Inner = styled.div`
  width: 100%;
  padding: 180px 0 202px;

  ${media.laptop(css`
    padding: 120px 0;
  `)}

  ${media.tablet(css`
    padding: 117px 0 115px;
  `)}
  
  ${media.mobile(css`
    padding: 82px 0 80px;
  `)}
`;

const Container = styled.div`
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 30px;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px;
  `)}
`;

export default Subscription;
